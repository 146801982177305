import React from 'react';

const Page404 = () => {
    return (
        <h1 className="bandeau text-center text-decoration-underline" >
            CETTE PAGE N'EXISTE PAS
        </h1>
    );
};

export default Page404;